<template>
    <div class="fixed-search" :class="{'custom-leftright-padding': !showMoreButton, 'custom-left-padding': showMoreButton}">
        <input id="searchBar" type="search" :placeholder="language.search" @keyup="search" />
        <div v-if="showMoreButton" @click="togglePopover" class="icon-container"><ion-icon :icon="ellipsisVertical"></ion-icon></div>
    </div>
</template>
<script lang="ts">

import {computed, defineComponent, getCurrentInstance, onMounted} from "vue";
import { executeWithoutSpinner} from "@/mixins/LoadingMixin";
import { ellipsisVertical } from 'ionicons/icons';
import {useStore} from "vuex";
import {IonIcon, popoverController} from "@ionic/vue";
import TogglePopover from "@/components/TogglePopover.vue";

export default defineComponent({
    name: 'SearchBar',
    components: {
        IonIcon
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        toggleChecked: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any, { emit }) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        let timer: number | undefined;
        const internalInstance = getCurrentInstance();
        const emitter = internalInstance?.appContext.config.globalProperties.emitter;
        
        const togglePopover = async (ev: Event) => {
            const popover = await popoverController
                .create({
                    component: TogglePopover,
                    cssClass: 'custom-popover',
                    event: ev,
                    translucent: true,
                    componentProps: {toggleChecked: props.toggleChecked}
                });
            await popover.present();
        }
        
        const search = (input: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                executeWithoutSpinner(async () => {
                    emit('search', input.target.value);
                    input.target.focus();
                } , language.value.errors.searching);
            }, 400);
        }

        onMounted(() => {
            emitter.on("toggled", (isChecked: boolean) => {
                emit('toggleArchived', isChecked);
            });
        });
        
        return {
            search,
            language,
            ellipsisVertical,
            togglePopover
        }
    }
});
</script>

<style scoped>
.icon-container {
    margin: 0 12px;
}

ion-icon {
    font-size: 30px;
    color: var(--color-step-600);
}

input {
    background-color: var(--color-step-950);
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    max-width: 500px;
    padding: 0 10px;
    outline: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-step-650);
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-step-650);
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-step-650);
}

.fixed-search {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: var(--color-step-1000);
    box-shadow: 0px 1px 10px #00000029;
    z-index: 2;
}
.fixed-search input {
    max-width: 100%;
}

.ltr .custom-left-padding {
    padding-left: 26px;
    padding-right: 5px;
}

.rtl .custom-left-padding{
    padding-right: 26px;
    padding-left: 5px;
}
</style>