<template>
    <ion-content class="ion-padding">
        <label>{{ language.showArchived }}</label>
        <ion-toggle
            @ionChange="handleToggle($event)"
            :checked="toggleChecked"
            mode="ios">
        </ion-toggle>
    </ion-content>
</template>

<script lang="ts">
import { IonToggle } from '@ionic/vue';
import {computed, defineComponent, getCurrentInstance} from 'vue';
import {useStore} from "vuex";

export default defineComponent({
    name: 'TogglePopover',
    components: { IonToggle },
    props: {
        toggleChecked: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const store = useStore();
        const internalInstance = getCurrentInstance();
        const emitter = internalInstance?.appContext.config.globalProperties.emitter;

        const handleToggle = (ev: CustomEvent) => {
            emitter?.emit("toggled", ev.detail.checked);
        }
        
        return { 
            emitter,
            language: computed(() => store.getters['app/language']),
            handleToggle
        }
    },
});
</script>

<style scoped>
label {
    color: var(--ion-color-primary);
    font-size: 18px;
}

ion-content::part(scroll) {
    display:  flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>