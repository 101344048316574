
import { IonToggle } from '@ionic/vue';
import {computed, defineComponent, getCurrentInstance} from 'vue';
import {useStore} from "vuex";

export default defineComponent({
    name: 'TogglePopover',
    components: { IonToggle },
    props: {
        toggleChecked: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const store = useStore();
        const internalInstance = getCurrentInstance();
        const emitter = internalInstance?.appContext.config.globalProperties.emitter;

        const handleToggle = (ev: CustomEvent) => {
            emitter?.emit("toggled", ev.detail.checked);
        }
        
        return { 
            emitter,
            language: computed(() => store.getters['app/language']),
            handleToggle
        }
    },
});
