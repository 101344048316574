
import {computed, ref, defineComponent} from 'vue';
import {useStore} from 'vuex';

import {uuid4} from '@capacitor/core/dist/esm/util';
import { closeOutline, chevronDownOutline, chevronUpOutline, checkmarkOutline } from 'ionicons/icons';

import {
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonButtons,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonTitle,
  IonModal
} from '@ionic/vue';

import DirectionIndicator from '@/components/DirectionIndicator.vue';
import SearchBar from '@/components/SearchBar.vue';

import {Book} from '@/models/BookModel';
import {ScriptureSelection} from '@/models/ScriptureSelectionModel';
import {Chapter} from '@/models/ChapterModel';
import {Verse} from '@/models/VerseModel';
import {ChapterReference} from '@/models/ChapterReferenceModel';
import {VerseReference} from '@/models/VerseReferenceModel';
import { execute } from '@/mixins/LoadingMixin';
import { getAppInstanceScripture } from '@/services/AppInstanceService';
import { bookmarks , bookmark , bookmarkOutline ,bookOutline} from 'ionicons/icons';

export default defineComponent({
  name: 'ScripturePicker',
  components: {
    IonContent,
    IonItem,
    IonIcon,
    IonLabel,
    IonButton,
    IonButtons,
    IonToolbar,
    IonFab,
    IonFabButton,
    IonTitle,
    IonModal,
    SearchBar,
    DirectionIndicator
  },
  props: {
    appInstanceId: {
      type: String,
      required: true
    },
    journeyId: {
      type: String,
      required: true
    },
    tab: {
      type: Number,
      required: true
    },
    scriptureReferences: {
      type: Array,
      required: true
    }
  },
  async created() {
    this.contentLoaded = false;
    await execute(async () => {
      this.scriptureData = await getAppInstanceScripture(this.appInstanceId);
      this.filteredScriptureData = JSON.parse(JSON.stringify(this.scriptureData));
      this.contentLoaded = true;
    }, this.language.errors.loadingJourney);
  },
  emits: ['onAdd', 'onRemove'],
  setup(props, ctx) {
    const store = useStore();
    const language = computed(() => store.getters['app/language']);
    const contentLoaded = ref(false);
    const modalOpen = ref(false);
    const modalTitle = ref(language.value.scripturePicker);
    const initialModalView = ref(true);
    const scriptureData = ref([] as Book[]);
    const filteredScriptureData = ref([] as Book[]);
    const book = ref({} as Book);
    const versesText = ref('');
    const selectedScripture = ref({} as ScriptureSelection);

    const versesSelected = computed(() => {
      return book.value.chapters?.filter((chapter: Chapter) => chapter.verses.filter((verse: Verse) => verse.selected).length > 0).length > 0;
    });

    const goBackToModalMain = () => {
      filteredScriptureData.value = JSON.parse(JSON.stringify(scriptureData.value));
      initialModalView.value = true;
      modalTitle.value = language.value.scripturePicker;
    }

    const clearSelectedScripture = () =>
      selectedScripture.value = {
        id: uuid4(),
        journeyId: props.journeyId,
        bookId: '',
        scriptureReference: '',
        chapters: [] as ChapterReference[],
        tabNum: props.tab
      } as ScriptureSelection;

    const showModal = (open: boolean) => {
      if (open) {
          goBackToModalMain();
          clearSelectedScripture();
          book.value = {} as Book;
      }
      modalOpen.value = open;
    }

    const searchBooks = async (search: string) => {
      filteredScriptureData.value = scriptureData.value.filter((book: Book) => book.name.toLowerCase().includes(search.toLowerCase()));
    }

    const displayChapters = (selectedBook: Book) => {
        initialModalView.value = false;
        modalTitle.value = selectedBook.name;
        if (!book.value.name || book.value.name !== selectedBook.name) {
            book.value = selectedBook;
            clearSelectedScripture();
        }
        selectedScripture.value.bookId = selectedBook.id;
    }

    const selectScripture = (verse: Verse, chapter: Chapter) => {
        const existingChapter = selectedScripture.value.chapters.find((chapterRef: ChapterReference) => chapterRef.chapter === chapter.number);
        if (verse.selected) {
            if (!existingChapter) {
                const newId = uuid4();
                selectedScripture.value.chapters.push({
                    id: newId,
                    scriptureReferenceId: selectedScripture.value.id,
                    chapter: chapter.number,
                    verses: [{
                        chapterId: newId,
                        verse: verse.number
                    }]
                });
            } else {
                existingChapter.verses.push({
                    chapterId: existingChapter.id,
                    verse: verse.number
                });
            }
        } else {
            if (existingChapter) {
                existingChapter.verses = existingChapter.verses.filter((verseRef: VerseReference) => verseRef.verse !== verse.number);
            }
        }
    }

    const handleRtlScriptureWithEnglish = (): string => {
        const verses = versesText.value.split(', ');
        return book.value.name + ' ' + verses.map((verse: string) => {
            return verse.split('-').map((x) => {
                return x.split(':').reverse().join(':');
            }).join('-');
        }).join(', ');
    }

    const handleVerseSelect = (verse: Verse, chapter: Chapter) => {
        verse.selected = !verse.selected;
        selectScripture(verse, chapter);
        versesText.value = '';
        let isSubsequent = false;
        let subsequentInitialChapterIndex = 0;
        let subsequentText = '';
        for (let chapterIndex = 0; chapterIndex < book.value.chapters.length; chapterIndex++) {
            const chapter = book.value.chapters[chapterIndex];             
            let chapterAdded = false;
            for (let verseIndex = 0; verseIndex < chapter.verses.length; verseIndex++) {
                const verse = chapter.verses[verseIndex];
                if (verse.selected) {
                    verse.connectToNext = false;
                    verse.connectToPrevious = false;
                    if (isSubsequent) {
                        const verseSections = versesText.value.split(',');
                        const lastSection = verseSections.pop()?.trim();
                        versesText.value = verseSections.join(',');
                        const startVerse = lastSection?.split('-')[0];
                        subsequentText = (versesText.value ? ', ' : '');
                        if (subsequentInitialChapterIndex !== chapterIndex) {
                            subsequentText += (startVerse?.search(':') === -1 ? book.value.chapters[subsequentInitialChapterIndex].number + ':' : '') + startVerse + '-' + chapter.number + ':' + verse.number;
                        } else {
                            subsequentText += startVerse + '-' + verse.number;
                        }
                        versesText.value += subsequentText;
                        
                        const continuingFromPrevChapter = verseIndex === 0 && chapterIndex > 0;
                        if (continuingFromPrevChapter) {
                            const prevChapter = book.value.chapters[chapterIndex-1];
                            prevChapter.verses[prevChapter.verses.length-1].connectToNext = true;
                        } else if (verseIndex > 0) {
                            chapter.verses[verseIndex - 1].connectToNext = true;
                        }
                        verse.connectToPrevious = true;
                    } else {
                        versesText.value += chapterAdded ? ', ' + (subsequentInitialChapterIndex !== chapterIndex ? chapter.number + ':' : '')  + verse.number : (versesText.value ? ', ' : '') + chapter.number + ':' + verse.number;
                        subsequentInitialChapterIndex = chapterIndex;
                    }
                    chapterAdded = true;
                    isSubsequent = true;
                } else {
                    subsequentText = '';
                    isSubsequent = false;
                }
            }
        }
        
        versesText.value = language.value.direction === 'rtl' && /\d/.test(versesText.value) ? handleRtlScriptureWithEnglish() : (book.value.name + ' ' + versesText.value);
    }

    const handleShiftSelect = (verse: Verse, chapter: Chapter) => {
        const verses = chapter.verses;
        const selectedVerseIndex = verses.indexOf(verse);
        let versesToSelect = verses.slice(0, selectedVerseIndex + 1);
        const chapterVersesUpToSelected = verses.slice(0, selectedVerseIndex);
        const closestPreSelectedVerse = chapterVersesUpToSelected.reverse().find((selectedVerse: Verse) => selectedVerse.selected);
        if (closestPreSelectedVerse) {
            const closestPreSelectedVerseIndex = verses.indexOf(closestPreSelectedVerse);
            versesToSelect = verses.slice(closestPreSelectedVerseIndex + 1, selectedVerseIndex + 1);
        }
        versesToSelect.forEach((selectedVerse: Verse) => handleVerseSelect(selectedVerse, chapter));
    }

    const addScriptureRef = () => {
      selectedScripture.value.scriptureReference = versesText.value;
      ctx.emit('onAdd', selectedScripture.value);
      showModal(false);
    }

    const removeScriptureRef = (index: number) => {
      const reference = props.scriptureReferences[index] as ScriptureSelection;
      ctx.emit('onRemove', reference.id)
    }

    return {
      store,
            language,
            showModal,
            modalOpen,
            modalTitle,
            initialModalView,
            goBackToModalMain,
            searchBooks,
            scriptureData,
            filteredScriptureData,
            book,
            displayChapters,
            versesSelected,
            handleVerseSelect,
            versesText,
            addScriptureRef,
            removeScriptureRef,
            handleShiftSelect,
            contentLoaded,
            closeOutline,
            chevronUpOutline,
            chevronDownOutline,
            checkmarkOutline,
            bookmarks,
            bookmark,
            bookmarkOutline,
            bookOutline
    }
  }
})
