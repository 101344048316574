import {DELETE, GET, POST, PUT} from './HTTP';
import { Journey } from "@/models/JourneyModel";

export function updateJourneys(journeys: Journey[]): Promise<any> {
    return PUT('/journeys', journeys);
}

export function updateJourney(journey: Journey): Promise<any> {
    return PUT(`/journeys/${journey.id}`, journey);
}

export function deleteJourney(journeyId: string): Promise<any> {
    return DELETE(`/journeys/${journeyId}`);
}

export function createJourney(data: any): Promise<any> {
    return POST(`/journeys`, data);
}

export function getJourney(journeyId: string): Promise<any> {
    return GET(`/journeys/${journeyId}`);
}