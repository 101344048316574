

import {computed, defineComponent, getCurrentInstance, onMounted} from "vue";
import { executeWithoutSpinner} from "@/mixins/LoadingMixin";
import { ellipsisVertical } from 'ionicons/icons';
import {useStore} from "vuex";
import {IonIcon, popoverController} from "@ionic/vue";
import TogglePopover from "@/components/TogglePopover.vue";

export default defineComponent({
    name: 'SearchBar',
    components: {
        IonIcon
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        toggleChecked: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any, { emit }) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        let timer: number | undefined;
        const internalInstance = getCurrentInstance();
        const emitter = internalInstance?.appContext.config.globalProperties.emitter;
        
        const togglePopover = async (ev: Event) => {
            const popover = await popoverController
                .create({
                    component: TogglePopover,
                    cssClass: 'custom-popover',
                    event: ev,
                    translucent: true,
                    componentProps: {toggleChecked: props.toggleChecked}
                });
            await popover.present();
        }
        
        const search = (input: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                executeWithoutSpinner(async () => {
                    emit('search', input.target.value);
                    input.target.focus();
                } , language.value.errors.searching);
            }, 400);
        }

        onMounted(() => {
            emitter.on("toggled", (isChecked: boolean) => {
                emit('toggleArchived', isChecked);
            });
        });
        
        return {
            search,
            language,
            ellipsisVertical,
            togglePopover
        }
    }
});
