<template>
    <ion-item lines="full" mode="md" v-if="!hasVideo">
        <button type="button" class="custom-button button-primary file-button" @click="addVideo">
            <ion-icon class="button-icon" :icon="videocam"></ion-icon>
            <span> {{ label }}</span>
        </button>
    </ion-item>
    <div class="video-container" v-if="hasVideo">
        <div class="video-header-container">
            <span class="video-header" style="text-align: start;">{{language.journeyVideoHeader}}</span>
            <button class="video-header video-remove" @click="removeVideo">{{language.journeyVideoRemove}}</button>
        </div>
        <video-link :tab-video-url="videoUrl" :tab-video-label="videoLabel" :disabled="true"></video-link>
    </div>    
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import {IonIcon, IonItem} from '@ionic/vue';
import VideoLink from '@/components/Video/VideoLink.vue';
import {useStore} from 'vuex';
import {openVideoModal} from '@/services/SelectModalService';
import {videocam} from 'ionicons/icons';
export default defineComponent({
    components: {
    IonItem,
    VideoLink,
    IonIcon
},
    props: {
        videoLabel: {
            type: String,
            required: true
        },
        videoUrl: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    emits: ['update:videoLabel', 'update:videoUrl'],
    setup(props, ctx) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const hasVideo = computed(() => props.videoLabel || props.videoUrl)

        const addVideo = async () => {
            const videoSelect = await openVideoModal();
            videoSelect.onWillDismiss().then(({ data }: any) => {
                ctx.emit('update:videoLabel', data.videoLabel.value);
                ctx.emit('update:videoUrl', data.videoUrl.value);
            });
        }

        const removeVideo = () => {
            ctx.emit('update:videoLabel', '');
            ctx.emit('update:videoUrl', '');
        }

        return {
            language,
            hasVideo,
            addVideo,
            removeVideo,
            videocam
        }
    }
})


</script>


<style scoped>

.custom-button {
    margin-bottom: 20px;
}

</style>
<style lang="css" src="../../components/Video/VideoStyling.css"></style>