import {DELETE, GET, POST, PUT} from './HTTP';
import { JourneySet } from "@/models/JourneySetModel";

export function getJourneySetGroups(appInstanceId: string, published: boolean): Promise<any>{
    return GET(`/journey-set-groups?appInstanceId=${appInstanceId}&published=${published}`);
}

export function getJourneySets(typeId: string, appInstanceId: string, published: boolean): Promise<JourneySet[]>{
    return GET(`/journey-sets?typeId=${typeId}&appInstanceId=${appInstanceId}&published=${published}`);
}

export function getJourneySet(id: string): Promise<JourneySet>{
    return GET(`/journey-sets/${id}`);
}

export function deleteJourneySet(id: string): Promise<any>{
    return DELETE(`/journey-sets/${id}`);
}

export function duplicateJourneySet(data: any): Promise<any>{
    return POST(`/journey-sets/${data.id}`, data);
}

export function createJourneySet(data: JourneySet): Promise<any> {
    return POST('/journey-sets', data);
}

export function updateJourneySet(data: JourneySet): Promise<any> {
    return PUT(`/journey-sets/${data.id}`, data);
}

export function updateJourneySetDefaults(data: JourneySet): Promise<any> {
    return PUT(`/journey-sets/${data.id}/defaults`, data);
}

export function updateJourneySetOrder(journeySets: JourneySet[]): Promise<any> {
    return PUT(`/journey-sets/ordering`, journeySets);
}

export function publish(id: string): Promise<any> {
    return POST(`/journey-sets/${id}/publish`);
}

export function unpublish(id: string): Promise<any> {
    return DELETE(`/journey-sets/${id}/publish`);
}

export function download(id: string): Promise<any> {
    return GET(`/journey-sets/${id}/download`, true);
}