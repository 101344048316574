import {ScriptureSelection} from "@/models/ScriptureSelectionModel";
import {AppInstance} from "@/models/AppInstanceModel";

export interface Journey {
    id: string;
    title: string;
    storyContent: string;
    scriptureAudio: string;
    scriptureAudioFilename: string;
    extraContent: string;
    extraAudio: string;
    extraAudioFilename: string;
    displayOrder: number;
    journeySetId: string;
    scriptureSelections: ScriptureSelection[];
    videoLabel: string;
    videoUrl: string;
    extraVideoUrl: string;
    extraVideoLabel: string;
    introTabTopText: string;
    introTabBottomText: string;
    introTabTopAudio: string;
    introTabTopAudioFilename: string;
    introTabBottomAudio: string;
    introTabBottomAudioFilename: string;
    introTabScriptureAudio: string;
    introTabScriptureAudioFilename: string;
    introTabVideoUrl: string;
    introTabVideoLabel: string;
    scriptureTabTopContent: string;
    scriptureTabTopAudio: string;
    scriptureTabTopAudioFilename: string;
    scriptureTabStoryAudio: string;
    scriptureTabStoryAudioFilename: string;
    extraTabTopContent: string;
    extraTabTopAudio: string;
    extraTabTopAudioFilename: string;
    extraTabScriptureAudio: string;
    extraTabScriptureAudioFilename: string;
    finalTabTopText: string;
    finalTabBottomText: string;
    finalTabVideoUrl: string;
    finalTabVideoLabel: string;
    finalTabTopAudio: string;
    finalTabTopAudioFilename: string;
    finalTabBottomAudio: string;
    finalTabBottomAudioFilename: string;
    finalTabScriptureAudio: string;
    finalTabScriptureAudioFilename: string;
}

export function validateJourney(journey: Journey): Journey {
    journey.scriptureAudio = (journey.scriptureAudio) ? journey.scriptureAudio : '';
    journey.scriptureAudioFilename = (journey.scriptureAudioFilename) ? journey.scriptureAudioFilename : '';
    journey.extraContent = (journey.extraContent) ? journey.extraContent : '';
    journey.extraAudio = (journey.extraAudio) ? journey.extraAudio : '';
    journey.extraAudioFilename = (journey.extraAudioFilename) ? journey.extraAudioFilename : '';
    journey.journeySetId = (journey.journeySetId) ? journey.journeySetId : '';
    journey.scriptureSelections = (journey.scriptureSelections) ? journey.scriptureSelections : [];
    journey.videoLabel = (journey.videoLabel) ? journey.videoLabel : '';
    journey.videoUrl = (journey.videoUrl) ? journey.videoUrl : '';
    journey.extraVideoUrl = (journey.extraVideoUrl) ? journey.extraVideoUrl : '';
    journey.extraVideoLabel = (journey.extraVideoLabel) ? journey.extraVideoLabel : '';
    journey.introTabTopText = (journey.introTabTopText) ? journey.introTabTopText : '';
    journey.introTabBottomText = (journey.introTabBottomText) ? journey.introTabBottomText : '';
    journey.introTabTopAudio = (journey.introTabTopAudio) ? journey.introTabTopAudio : '';
    journey.introTabTopAudioFilename = (journey.introTabTopAudioFilename) ? journey.introTabTopAudioFilename : '';
    journey.introTabBottomAudio = (journey.introTabBottomAudio) ? journey.introTabBottomAudio : '';
    journey.introTabBottomAudioFilename = (journey.introTabBottomAudioFilename) ? journey.introTabBottomAudioFilename : '';
    journey.introTabScriptureAudio = (journey.introTabScriptureAudio) ? journey.introTabScriptureAudio : '';
    journey.introTabScriptureAudioFilename = (journey.introTabScriptureAudioFilename) ? journey.introTabScriptureAudioFilename : '';
    journey.introTabVideoUrl = (journey.introTabVideoUrl) ? journey.introTabVideoUrl : '';
    journey.introTabVideoLabel = (journey.introTabVideoLabel) ? journey.introTabVideoLabel : '';
    journey.scriptureTabTopContent = (journey.scriptureTabTopContent) ? journey.scriptureTabTopContent : '';
    journey.scriptureTabTopAudio = (journey.scriptureTabTopAudio) ? journey.scriptureTabTopAudio : '';
    journey.scriptureTabTopAudioFilename = (journey.scriptureTabTopAudioFilename) ? journey.scriptureTabTopAudioFilename : '';
    journey.scriptureTabStoryAudio = (journey.scriptureTabStoryAudio) ? journey.scriptureTabStoryAudio : '';
    journey.scriptureTabStoryAudioFilename = (journey.scriptureTabStoryAudioFilename) ? journey.scriptureTabStoryAudioFilename : '';
    journey.extraTabTopContent = (journey.extraTabTopContent) ? journey.extraTabTopContent : '';
    journey.extraTabTopAudio = (journey.extraTabTopAudio) ? journey.extraTabTopAudio : '';
    journey.extraTabTopAudioFilename = (journey.extraTabTopAudioFilename) ? journey.extraTabTopAudioFilename : '';
    journey.extraTabScriptureAudio = (journey.extraTabScriptureAudio) ? journey.extraTabScriptureAudio : '';
    journey.extraTabScriptureAudioFilename = (journey.extraTabScriptureAudioFilename) ? journey.extraTabScriptureAudioFilename : '';
    journey.finalTabTopText = (journey.finalTabTopText) ? journey.finalTabTopText : '';
    journey.finalTabBottomText = (journey.finalTabBottomText) ? journey.finalTabBottomText : '';
    journey.finalTabVideoUrl = (journey.finalTabVideoUrl) ? journey.finalTabVideoUrl : '';
    journey.finalTabVideoLabel = (journey.finalTabVideoLabel) ? journey.finalTabVideoLabel : '';
    journey.finalTabTopAudio = (journey.finalTabTopAudio) ? journey.finalTabTopAudio : '';
    journey.finalTabTopAudioFilename = (journey.finalTabTopAudioFilename) ? journey.finalTabTopAudioFilename : '';
    journey.finalTabBottomAudio = (journey.finalTabBottomAudio) ? journey.finalTabBottomAudio : '';
    journey.finalTabBottomAudioFilename = (journey.finalTabBottomAudioFilename) ? journey.finalTabBottomAudioFilename : '';
    journey.finalTabScriptureAudio = (journey.finalTabScriptureAudio) ? journey.finalTabScriptureAudio : '';
    return journey;
}

export function getMissingJourneyInfo(journey: Journey, needsExtraInfo: boolean, appInstance: AppInstance, language: any): string {
    let message = '';
    if (!journey.title.trim()) {
        message += `<li>${ language.journeyTitle }</li>`;
    }
/*     if (appInstance.allowStorytelling && !journey.storyContent?.trim()) {
        message += `<li>${ language.journeyStoryContent }</li>`;
    } */
    if (!journey.scriptureSelections || journey.scriptureSelections.length === 0) {
        message += `<li>${ language.journeyScriptureSelections }</li>`;
    }
    if (!journey.scriptureAudio?.trim()) {
        message += `<li>${ language.journeyScriptureAudio }</li>`;
    }
    if (needsExtraInfo && !journey.extraContent?.trim()) {
        message += `<li>${ language.journeyAddtlTabContent }</li>`;
    }
    if (needsExtraInfo && !journey.extraAudio?.trim()) {
        message += `<li>${ language.journeyAddtlTabAudio }</li>`;
    }
    return message;
}