<template>
    <ion-grid :class="[{empty: emptyClass} , 'preview-place-holder']" >
        <ion-row class="template-placeholder-label">
            <ion-col size="6" offset="3" style="text-align:center;">
                <ion-icon class="icon" style="color:#999999;margin-bottom: 4px!important;" :icon="documentText"></ion-icon>
                <span class="template-text">Template Content</span>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="6" offset="3">
                <vue3-markdown-it breaks html :source="previewText" class="template-preview-markdown"/>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts">
import {ref,defineComponent, computed, nextTick } from 'vue';
import {
    IonItem,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    loadingController,
    IonLabel
} from '@ionic/vue';
import {useStore} from "vuex";
import {documentText , closeOutline, musicalNote} from 'ionicons/icons';

export default defineComponent({
    name: "TemplateItemPlaceholder",
    components: {
        IonIcon,
        IonGrid,
        IonRow,
        IonCol
    },
    props: {
        previewText:{
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false
        }
    },
    setup(props) {
        
        const emptyClass = computed(() => {
            if (props.previewText.trim() == ''){
                return true;
            }
                return false;
        })

        return {
            closeOutline,
            musicalNote,
            documentText,
            emptyClass
        }
    }
})
</script>


<style scoped>
  
.custom-button {
    margin-bottom: 20px;
}


/* .template-preview-markdown > div:before{
    content:'';
    width:100%;
    height:100%;    
    position:absolute;
    left:0;
    top: 0;
    background:linear-gradient(rgba(255,255,255,.4) 50px, rgba(255,255,255,.7));
}*/

.empty{
    display:none;
}

.template-text , ion-icon.template-text{
    color: var(--color-step-600)!important;
}


</style>