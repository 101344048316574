
import {ref,defineComponent, computed, nextTick } from 'vue';
import {
    IonItem,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    loadingController,
    IonLabel
} from '@ionic/vue';
import {useStore} from "vuex";
import {documentText , closeOutline, musicalNote} from 'ionicons/icons';

export default defineComponent({
    name: "TemplateItemPlaceholder",
    components: {
        IonIcon,
        IonGrid,
        IonRow,
        IonCol
    },
    props: {
        previewText:{
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false
        }
    },
    setup(props) {
        
        const emptyClass = computed(() => {
            if (props.previewText.trim() == ''){
                return true;
            }
                return false;
        })

        return {
            closeOutline,
            musicalNote,
            documentText,
            emptyClass
        }
    }
})
